import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Useful Command Line Shortcuts`}</h1>
    <p>{`As developers, we use the terminal all the time, it makes sense to learn some shortcuts that can help you do things in the command line. `}</p>
    <p>{`Learning these will also help you boost your productivity because you don't have to retype your commands again just because you found a mistake.`}</p>
    <p>{`I've also created a short(ish) video that shows some of the shortcuts mentioned in this article.`}</p>
    <div className="flex justify-center mt-12">
  <iframe width="560" height="315" src="https://www.youtube.com/embed/yZZmW3KTYr4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
    </div>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` When I recorded this video, I thought the shortcut `}<inlineCode parentName="p">{`CTRL+U`}</inlineCode>{` was deleting the line, but it's just cutting the whole line, you can paste the text with `}<inlineCode parentName="p">{`CTRL+Y`}</inlineCode>{`.`}</p>
    </blockquote>
    <h2>{`Shortcuts for moving around`}</h2>
    <p>{`Let's be honest how many times do we write a command, just to notice that around the beginning of the line there is a mistake and we delete everything and type the command again? These shortcuts will help you move the cursor easier without the need to delete the whole thing.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + A`}</inlineCode>{` - Moves the cursor to the beginning of the line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + E`}</inlineCode>{` - Moves the cursor to the end of the line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + arrow keys`}</inlineCode>{` - Moves the cursor from word to word `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ALT + B`}</inlineCode>{` - Moves the cursor back from word to word`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ALT + F`}</inlineCode>{` - Moves cursors forward from word to word`}</li>
    </ul>
    <h2>{`Cutting and pasting`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + W`}</inlineCode>{` - Cut the word left of the cursor`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + K`}</inlineCode>{` - Cut the entire text at the right of the cursor`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`ALT + W`}</inlineCode>{` - Cut the entire text at the left of the cursor`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + U`}</inlineCode>{` - Cut the entire line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + Y`}</inlineCode>{` - Paste the previous cut text`}</li>
    </ul>
    <h2>{`Text manipulation`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + T`}</inlineCode>{` - Swap two letters between the cursor`}
        <ul parentName="li">
          <li parentName="ul">{`Example: `}<inlineCode parentName="li">{`this`}</inlineCode>{` if the cursor is between the `}<strong parentName="li">{`h`}</strong>{` and `}<strong parentName="li">{`i`}</strong>{` it turns into `}<inlineCode parentName="li">{`tihs`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ESC + T`}</inlineCode>{` - Swap two words between the cursor`}
        <ul parentName="li">
          <li parentName="ul">{`Example: `}<inlineCode parentName="li">{`this text`}</inlineCode>{` will become `}<inlineCode parentName="li">{`text this`}</inlineCode></li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`ESC + U`}</inlineCode>{` - Uppercases text right of the cursor - moves cursor to the end of word`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + Q`}</inlineCode>{` - Deletes text from line - clears line only`}</li>
    </ul>
    <h2>{`Other useful shortcuts`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + L`}</inlineCode>{` - Clears the terminal -> No need to write `}<inlineCode parentName="li">{`clear`}</inlineCode>{` all the time!`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`CTRL + R`}</inlineCode>{` - Reverse search your history for a term`}
        <ul parentName="li">
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`CTRL + R`}</inlineCode>{` again to cycle through results`}</li>
        </ul>
      </li>
      <li parentName="ul"><inlineCode parentName="li">{`!!`}</inlineCode>{` Repeat the last command used in the terminal`}
        <ul parentName="li">
          <li parentName="ul">{`Use `}<inlineCode parentName="li">{`sudo !!`}</inlineCode>{` to repeat the last command with `}<inlineCode parentName="li">{`sudo`}</inlineCode></li>
        </ul>
      </li>
    </ul>
    <h2>{`Cheatsheets`}</h2>
    <p>{`I've created a cheatsheet with these commands, hopefully this will help you get used to using the commands.`}</p>
    <h3>{`Dark Theme`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ff18b68fbca034910f339026fe6ab672/29114/terminal_shortcuts_dark.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACdUlEQVQoz1WTa0/aYBTH+RpeolRuBXqVcukKpVBACq4FnDChVC5TEdzmspjsq/jaTZcsmCxL9vX+Oy0uy178ntOey/85z3naUHy7jCgR2daJzXPAVhmMUMW+0QAjmWDUOsJaPfBFt/R/eUFtGTEisWMglKLlPmnhF9fGM9fCWrKxLpxinXuDdbKF57gV2NtwHU8JCz99n9LFWu0HeT/4Nn5T3ZdYA2ESDbFbBurSAMe5MV6zI4zVBTztOmBm3GBaXmGQnaPI2LCUEdpZFwNljqm+wqS0wnlpCTd3BT3SoS51hBLbBkRuAIkEZdmFoVyikr1CNeezgMpPcBgfQdi3IYtDyLwLTZgFsWp+AbOwoJorCAcdxH1Bv0NePAN36EFgxygXljC19zBLH1AjSrlrKKyHxJ6DdMZFWh5DO7xEw7iFRkIKN4XETpBmuhvBGHVoxY/hsQ5GURtT4ZToY0Z2xr3BO7LNmIMTpgk3aWOYdDAh/4zGNONP4SW78CjeZKzggkIMLXf+4KUuvoodPObf4rHo4jHbxzfOwRPvYBk5wr3o4LsfF7qbHHWIB7GHB87GM9m7eAu+VihBn0A61qOjeMjQbMqFFcrqCnp+CS27gJQ8RyrsgEsPkU6NkZcvgrEUKabwM3AxlxgjfdDbHDlCgnKiC0Udo5Kb48T8hB7RqXxEnS5IS3mQ9mxkM0NkxRFa6hL9o8/oUrxn3qKtrdCgS5QPXmZ4sl9BIdqDQrvrwhQWJRwVrtGkQp9q5gJimARTZ8QIJm1ivdrEWsWb4F0X5pAjLx1mdg2kdk2ijiTB7tTA7tY2lgh8Qbz2kvPX/3+en+P/dX8Ah5eJX53Vy84AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "terminal shortcuts dark",
            "title": "terminal shortcuts dark",
            "src": "/static/ff18b68fbca034910f339026fe6ab672/a6d36/terminal_shortcuts_dark.png",
            "srcSet": ["/static/ff18b68fbca034910f339026fe6ab672/222b7/terminal_shortcuts_dark.png 163w", "/static/ff18b68fbca034910f339026fe6ab672/ff46a/terminal_shortcuts_dark.png 325w", "/static/ff18b68fbca034910f339026fe6ab672/a6d36/terminal_shortcuts_dark.png 650w", "/static/ff18b68fbca034910f339026fe6ab672/e548f/terminal_shortcuts_dark.png 975w", "/static/ff18b68fbca034910f339026fe6ab672/3c492/terminal_shortcuts_dark.png 1300w", "/static/ff18b68fbca034910f339026fe6ab672/29114/terminal_shortcuts_dark.png 1920w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Light Theme`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8cc2a8846dea00ea807d2e6cac56cf5b/29114/terminal_shortcuts_light.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.44171779141104%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACJ0lEQVQoz3VTW2sTQRjd/+iLglIwiCgSXyoi4o/wQQqNaFvFFtSfULCtFH3wwbRJSpLN/bLJJnvfuezt+M1svD104TDMnDNnvrPfjME5h+TifzA1SiSzBZJWF8nEgjQHSDqE2VJzpeYvBIExBiNkEbYHH3Cz8wq3OzuomK9R7R/gUX8fW70a7gxq2DJ38d4+x73hHm6ZO7hvvsHjwTutu2vWcKP9EruzYxQihcGEwNSbY+SMMU4cNFcDNOy+xs95B3Wri7Y7wUoEGNM4Jl3bm+JiYeJi2cMloeWOYQsfnHEYnAzBEyBgAMUQMQOPCGEMFkYokgzqy7KcdBKQGTKZak4h9kMdPydeR1YVgpGhKPTGNOQQXkyINIqYuJIC4rTU8RTx2qd9NM82HJ2nDaWQuPJGOHEaOPfb+Lpu4mzVxIndwBf7EsfLOrrhHN+8Dq2TxmmRpoWzDX+q9P4VWsFEN8fIRYJ96xSVbg3V3gGeDo/wYvQJT4aHujEP+3v4vPyOZ/0jPOi91WtK83z0EdujQ1SpORVqjPJQXoYqEynFkCpvGTkNOPJQlJE2cRSno8sCGfH6V/yO+29kJum+CRK4HqLAx2xhYW7NYS0XCMIASZoiyTM6JEBBd9bxXExmUyyIV7qVs0YQhVqnDfmPOlUV0ekFJDXIdV34vg/P8zRYHCMlcZ6oijJEUVRyvqe1ap4Ql/4xtNd0y6mzVKm8BnzDX6dRhkKUL+UXEUosHcbA+5EAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "terminal shortcuts light",
            "title": "terminal shortcuts light",
            "src": "/static/8cc2a8846dea00ea807d2e6cac56cf5b/a6d36/terminal_shortcuts_light.png",
            "srcSet": ["/static/8cc2a8846dea00ea807d2e6cac56cf5b/222b7/terminal_shortcuts_light.png 163w", "/static/8cc2a8846dea00ea807d2e6cac56cf5b/ff46a/terminal_shortcuts_light.png 325w", "/static/8cc2a8846dea00ea807d2e6cac56cf5b/a6d36/terminal_shortcuts_light.png 650w", "/static/8cc2a8846dea00ea807d2e6cac56cf5b/e548f/terminal_shortcuts_light.png 975w", "/static/8cc2a8846dea00ea807d2e6cac56cf5b/3c492/terminal_shortcuts_light.png 1300w", "/static/8cc2a8846dea00ea807d2e6cac56cf5b/29114/terminal_shortcuts_light.png 1920w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      